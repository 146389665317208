<template>
  <footer class="footer">
    <div class="grid-x main">
      <div class="cell small-12 medium-10 medium-offset-1">
        <p class="title align-center-small">A brand of reh kendermann</p>
        <hr class="divider"/>
        <div class="grid-x">
          <div class="cell small-12 medium-6">
            <div class="grid-x">
              <div class="cell small-12 medium-4 medium-order-2 align-center-small">
                <router-link class="main-link" :to="{ name: 'terms-conditions' }">Terms & Conditions</router-link>
              </div>
              <div class="cell small-12 medium-4 medium-order-3 align-center-small">
                <router-link class="main-link" :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
              </div>
              <div class="cell small-12 medium-4 medium-order-1 align-center-small">
                <button class="main-link -button" :class="{'-active': contactActive}" @click="toggleContact">Contact</button>
              </div>
            </div>
          </div>
          <div class="cell small-12 medium-6">
            <div class="grid-x">
              <div class="cell small-12 medium-4 medium-offset-2">
                <social-links/>
              </div>
              <div class="cell small-12 medium-4 medium-offset-2 logo-copy">
                <!-- <a href="https://www.drinkaware.co.uk/" target="_blank"><img class="da-logo" :src="require('@/assets/images/drink-aware-logo.png')" alt=""></a> -->
                <div class="copyright">© Copyright 2020</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="contactActive" class="grid-x contact">
      <div class="cell small-12 medium-10 medium-offset-1">
        <p class="contact-title">Contact US</p>
        <hr class="divider"/>
        <div class="grid-x">
          <div class="cell small-12 medium-4">
            <address class="copy">
              Reh Kendermann GmbH Weinkellerei<br>
              Am Ockenheimer Graben 35<br>
              D-55411 Bingen/Rhein
            </address><br>
            <p class="copy">phone: <a class="copy" href="tel:+49 (0) 67 21 901-0">+49 (0) 67 21 901-0</a></p>
            <p class="copy">fax: <a class="copy" href="tel:+49 (0) 67 21 901-240">+49 (0) 67 21 901-240</a></p><br>
            <p class="copy"><a class="copy" href="mailto:info@black-tower.de">info@black-tower.de</a></p>
          </div>
          <div class="cell small-12 medium-4">
            <div v-if="isCurrentSitePrimary">
              <p class="copy -bold">Wholesale</p><br>
              <p class="copy"><a class="copy" href="mailto:blacktower@northsouthwines.co.uk">blacktower@northsouthwines.co.uk</a></p><br class="show-for-medium">
            </div>
            <p class="copy -bold">PR</p><br>
            <p class="copy">For PR enquiries please contact LDR on <a class="copy" href="mailto:blacktower@ldrlondon.com">blacktower@ldrlondon.com</a></p>
          </div>
          <div class="cell small-12 medium-4">
            <p class="copy -bold">Other Sites</p><br>
            <p v-for="site in sites" :key="site.id">
              <a
                :href="`https://${site.domain}`"
                class="copy -site"
                :class="{'-current': site.current}"
              >{{ site.description }}</a>
            </p>
            <p><a
              href="https://www.reh-kendermann.de/"
              class="copy -site"
            >Reh Kendermann</a></p>
          </div>
        </div>
      </div>
    </div>

    <cookie-law buttonText="Accept">
      <div slot="message">
        This website uses cookies to provide you with the optimal browsing experience.
        We only use cookies that are essential to the function of the website and for usage-data analytics.
        Further information can be found in the <a href='/privacy-policy'>privacy policy</a>.
      </div>
    </cookie-law>
  </footer>
</template>

<script>
  import CookieLaw from 'vue-cookie-law';
  import SocialLinks from './SocialLinks.vue';
  import { mapState } from 'vuex';

  export default {
    components: {
      CookieLaw,
      SocialLinks
    },
    data() {
      return {
        contactActive: false
      }
    },
    methods: {
      toggleContact() {
        this.contactActive = !this.contactActive;
        this.$nextTick(() => {
          if (this.contactActive) {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth'
            });
          }
        })
      }
    },
    computed: {
      ...mapState(['sites']),
      isCurrentSitePrimary() {
        if (this.sites.length === 0) {
          return false;
        }
        return this.sites.find(site => {
          return site.current;
        }).blog_id == '1';
      }
    }
  }
</script>

<style lang="scss">
.Cookie__content {
  font: 300 16px/1.2 CenturyGothicPaneuropean;
}
</style>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .main {
    background-color: #FEE9C6;
    padding: 18px vw-relative(20, 320);
    @include breakpoint(medium) {
      padding: 18px 0;
    }
  }
  .align-center-small {
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  .title {
    font: 14px/1 CenturyGothicPaneuropean;
    text-transform: uppercase;
    @include breakpoint(medium) {
      font-size: vw-relative(16);
    }
  }
  .divider {
    width: 100%;
    border-top: 1px solid #7E7A7A;
    margin: 12px 0;
  }
  .main-link {
    font: bold 14px/1 CenturyGothicPaneuropean;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .1em;
    padding: .5em 0;
    display: inline-block;
    @include breakpoint(medium) {
      font-size: vw-relative(12);
    }
    &.-button {
      display: inline-flex;
      align-items: center;
      &:after {
        content: '';
        margin-left: .5em;
        background-image: url('~@/assets/images/arrow.svg');
        width: 1em;
        height: 1em;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        transition: transform .2s;
      }
      &.-active {
        &:after {
          transform: scaleY(-1);
        }
      }
    }
  }
  .da-logo {
    padding: vw-relative(6) 0 vw-relative(6) vw-relative(12);
    @include breakpoint(small only) {
      width: 120px;
    }
  }
  .copyright {
    font: bold 14px/1 CenturyGothicPaneuropean;
    white-space: nowrap;
    color: #5C5C5C;
    text-align: right;
    @include breakpoint(medium) {
      font-size: vw-relative(10);
    }
  }
  .contact {
    background-color: #E7E6F4;
    padding: 30px vw-relative(20, 320);
    @include breakpoint(medium) {
      padding: 30px 0 60px;
    }
  }
  .contact-title {
    font: bold 14px/1 CenturyGothicPaneuropean;
    text-transform: uppercase;
    @include breakpoint(medium) {
      font-size: vw-relative(19);
    }
  }
  .copy {
    font: 16px/1.2 CenturyGothicPaneuropean;
    text-decoration: none;
    @include breakpoint(medium) {
      font-size: vw-relative(17);
    }
    &.-bold {
      font-weight: bold;
      text-transform: uppercase;
      @include breakpoint(small only) {
        margin-top: 3em;
      }
    }
    &.-site {
      display: inline-block;
      margin-bottom: 1.5em;
      &.-current {
        color: #B3B0B0;
      }
      &:before {
        content: '';
        display: inline-block;
        height: .8em;
        width: .8em;
        margin-right: .5em;
        background-image: url('~@/assets/images/link.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  .logo-copy {
    @include breakpoint(small only) {
      display: flex;
      justify-content: space-between;
    }
  }
</style>

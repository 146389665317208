<template>
  <nav class="navigation" :class="{'-pad-clone': padClone}">
    <router-link :to="{ name: 'home' }" @click.native="clickLink"><img class="logo" :src="require('@/assets/images/logo.png')" alt=""></router-link>
    <div class="links" :class="{'-active': showLinks}">
      <router-link class="link" :to="{ name: 'our-story' }" @click.native="clickLink">Our Story</router-link>
      <span class="category-button-holder">
        <button class="link -wines" :class="{'-active': showCategories}" @click="showCategories = !showCategories">
          Our Wines
        </button>
        <div v-if="showCategories" class="categories">
          <router-link v-for="category in wineCategories" class="category" :key="category.id" :to="{ name: 'wine-category', params: {slug: category.slug} }" @click.native="clickLink">
            <div
              class="category-inner"
              :style="{
                backgroundColor: category.colour,
                color: category.textColour
              }"
            >
              <p class="category-name">{{ category.name }}</p>
              <img class="category-bottle" :class="{['-' + category.packagingType]: true}" :src="category.bottle.url" :width="category.bottle.width"  :height="category.bottle.height">
            </div>
          </router-link>
        </div>
      </span>
      <router-link v-if="isCurrentSitePrimary" class="link" :to="{ name: 'where-to-buy' }" @click.native="clickLink">Where to Buy</router-link>
      <router-link class="link" :to="{ name: 'whats-on' }" @click.native="clickLink">What's On</router-link>
      <div class="site-select">
        <a
          v-for="site in sites"
          :key="site.id"
          :href="`https://${site.domain}`"
          class="link -site"
          :class="{'-current': site.current}"
        >{{ site.description }}</a>
      </div>
    </div>
    <button class="hamburger hamburger--slider hide-for-medium" :class="{'is-active': showLinks}" @click="showLinks = !showLinks">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </nav>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    props: {
      padClone: Boolean
    },
    data() {
      return {
        showCategories: false,
        showLinks: false
      }
    },
    mounted() {
      this.fetchSites();
      this.fetchWineCategories();
    },
    computed: {
      ...mapState(['sites', 'wineCategories']),
      isCurrentSitePrimary() {
        if (this.sites.length === 0) {
          return false;
        }
        return this.sites.find(site => {
          return site.current;
        }).blog_id == '1';
      }
    },
    methods: {
      ...mapActions(['fetchSites', 'fetchWineCategories']),
      siteExtension(domain) {
        let parts = domain.split('.');
        parts.shift();
        return parts.join('.');
      },
      clickLink() {
         this.showCategories = false;
         this.showLinks = false;
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  @import 'hamburgers/_sass/hamburgers/hamburgers.scss';
  .navigation {
    background-color: rgba(#FFF, .2);
    padding: 10px vw-relative(16, 320);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: map-get($z-indexes, 'nav');
    &.-pad-clone {
      position: static;
      opacity: 0;
      visibility: hidden;
    }
    @include breakpoint(medium) {
      padding: vw-relative(8) vw-relative(8) vw-relative(8) vw-relative(20);
    }
  }
  .logo {
    height: 30px;
    width: auto;
    @include breakpoint(xlarge) {
      height: 52px;
    }
  }
  .links {
    display: flex;
    align-items: center;
    @include breakpoint(small only) {
      transform: translateY(-100%);
      padding: 60px 0;
      position: absolute;
      top: 0;
      z-index: -1;
      left: 0;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #FEE9C6;
      transition: transform .2s;
      overflow: auto;
      &.-active {
        transform: translate(0);
      }
    }
  }
  .link {
    font: bold 16px/1 CenturyGothicPaneuropean;
    letter-spacing: -.034em;
    text-decoration: none;
    margin: 0 .9em;
    padding: .9em 0;
    display: inline-block;
    @include breakpoint(medium) {
      font-size: 12px;
    }
    @include breakpoint(xlarge) {
      font-size: 16px;
    }
    &.-site {
      padding: 0;
      line-height: 1.2em;
    }
    &.-current {
      text-decoration: underline;
    }
    &.-wines {
      position: relative;
      &:after {
        content: '';
        height: .8em;
        width: .8em;
        display: inline-block;
        margin-left: .3em;
        background-image: url('~@/assets/images/arrow.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transition: transform .5s;
      }
      &.-active:after {
        transform: scaleY(-1);
      }
    }
  }
  .site-select {
    display: inline-flex;
    flex-direction: column;
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  .category-button-holder {
    @include breakpoint(small only) {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
  .categories {
    display: flex;
    background-color: rgba(#FFF, .7);
    padding: 10px;
    @include breakpoint(small only) {
      width: 100%;
      padding: 10px 5px 0;
      flex-wrap: wrap;
    }
    @include breakpoint(medium) {
      position: fixed;
      right: 50px;
      top: 45px;
    }
    @include breakpoint(large) {
      padding: 20px;
    }
    @include breakpoint(xlarge) {
      top: 60px;
      right: 50px;
    }
  }
  .category {
    display: block;
    padding: 5px;
    text-decoration: none;
    @include breakpoint(small only) {
      width: calc(100%/3);
      margin-bottom: 10px;
      padding: 0 5px;
    }
    @include breakpoint(medium) {
      width: 126px;
      height: 126px;
    }
    @include breakpoint(large) {
      width: 156px;
      height: 156px;
    }
    @include breakpoint(xlarge) {
      width: 196px;
      height: 196px;
    }
    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      overflow: hidden;
      @include breakpoint(small only) {
        padding-top: 100%;
        position: relative;
        overflow: hidden;
      }
    }
    &-name {
      width: 100%;
      text-align: center;
      font: bold 9px/1 CenturyGothicPaneuropean;
      text-transform: uppercase;
      letter-spacing: rem-calc(-.54px, 14px);
      padding: 1em 0;
      @include breakpoint(small only) {
        position: absolute;
        top: 0;
      }
      @include breakpoint(medium) {
        font-size: 12px;
      }
      @include breakpoint(xlarge) {
        font-size: 14px;
      }
    }
    &-bottle {
      width: 25%;
      height: auto;
      &.-box {
        width: 55%;
      }
      @include breakpoint(small only) {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
</style>
